import React, { useState } from 'react';
import { View, Text, Image, TouchableOpacity, Modal, StyleSheet, Dimensions } from "react-native";
import { useNavigation } from '@react-navigation/native';
import Common from '../CommonStyles/common';
import { useGlobalContext } from "../GlobalContext/GlobalContext";
import { CustomButton } from "./WebComponent";

const { width } = Dimensions.get('window');
const isMobile = width < 768;

export default function WHeader() {
    const navigation = useNavigation();
    const [menuVisible, setMenuVisible] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const { user, setUser, setJwt, setRole } = useGlobalContext();

    const handleLogout = () => {
        setUser(null);
        setJwt(null);
        setRole(null);
        navigation.navigate('WLogin');
    };

    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={() => navigation.navigate('WebHome')} style={styles.logoContainer}>
                <Image 
                    source={require("../../resource/Web/webicon_samll.png")}
                    style={styles.logo}
                />
            </TouchableOpacity>

            {user ? (
                <View style={styles.userContainer}>
                    <TouchableOpacity onPress={() => setMenuVisible(!menuVisible)} style={styles.userInfo}>
                        <Text style={styles.userName}>{user.shop.name} {user.role}</Text>
                        <Image 
                            source={require("../../resource/Web/play-fill.png")}
                            style={styles.dropdownIcon}
                        />
                        <Image 
                            source={require("../../resource/Web/person-circle.png")}
                            style={styles.userIcon}
                        />
                    </TouchableOpacity>
                    <Modal
                        transparent={true}
                        visible={menuVisible}
                        onRequestClose={() => setMenuVisible(false)}
                    >
                        <TouchableOpacity 
                            style={styles.modalOverlay} 
                            activeOpacity={1} 
                            onPressOut={() => setMenuVisible(false)}
                        >
                            <View style={styles.dropdown}>
                                <TouchableOpacity onPress={() => {
                                    setMenuVisible(false);
                                    setDetailModal(true);
                                }} style={styles.dropdownItem}>
                                    <Image 
                                        source={require("../../resource/Web/gear.png")}
                                        style={styles.dropdownIcon}
                                    />
                                    <Text style={styles.dropdownText}>Profile</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={handleLogout} style={styles.dropdownItem}>
                                    <Image 
                                        source={require("../../resource/Web/power.png")}
                                        style={styles.dropdownIcon}
                                    />
                                    <Text style={styles.dropdownText}>Logout</Text>
                                </TouchableOpacity>
                            </View>
                        </TouchableOpacity>
                    </Modal>
                </View>
            ) : (
                <View style={styles.loginContainer}>
                    <TouchableOpacity onPress={() => navigation.navigate('WLogin')}>
                        <Text style={styles.loginText}>登录</Text>
                    </TouchableOpacity>
                </View>
            )}

            <Modal
                transparent={true}
                visible={detailModal}
                onRequestClose={() => setDetailModal(false)}
            >
                {user && (
                    <View style={styles.profileModalOverlay}>
                        <View style={styles.profileModal}>
                            <Text style={styles.profileTitle}>Profile</Text>
                            <View style={styles.profileItem}>
                                <Text style={styles.profileLabel}>Username:</Text>
                                <Text style={styles.profileValue}>{user.nickName}</Text>
                            </View>
                            <View style={styles.profileItem}>
                                <Text style={styles.profileLabel}>Email:</Text>
                                <Text style={styles.profileValue}>{user.email}</Text>
                            </View>
                            <View style={styles.profileItem}>
                                <Text style={styles.profileLabel}>Description:</Text>
                                <Text style={styles.profileValue}>{user.email}</Text>
                            </View>
                            <View style={styles.profileItem}>
                                <Text style={styles.profileLabel}>Phone Number:</Text>
                                <Text style={styles.profileValue}>{user.phone}</Text>
                            </View>
                            <CustomButton
                                title="Back"
                                onPress={() => setDetailModal(false)}
                                style={styles.backButton}
                            />
                        </View>
                    </View>
                )}
            </Modal>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "100%",
        backgroundColor: "#fff",
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 5,
        paddingHorizontal: isMobile ? 10 : 20,
        paddingVertical: 10,
    },
    logoContainer: {
        flexDirection: 'row',
        alignItems: "center",
    },
    logo: {
        width: isMobile ? 50 : 77 * Common.WIDTH_DIVISOR,
        height: isMobile ? 24 : 37 * Common.WIDTH_DIVISOR,
    },
    userContainer: {
        flexDirection: "row",
        alignItems: "center",
    },
    userInfo: {
        flexDirection: "row",
        alignItems: "center",
    },
    userName: {
        fontSize: isMobile ? 14 : 18 * Common.WIDTH_DIVISOR,
        fontWeight: "600",
        color: "#656464",
        marginRight: 10,
    },
    dropdownIcon: {
        width: isMobile ? 12 : 18 * Common.WIDTH_DIVISOR,
        height: isMobile ? 10 : 16 * Common.WIDTH_DIVISOR,
        marginRight: 10,
    },
    userIcon: {
        width: isMobile ? 30 : 41 * Common.WIDTH_DIVISOR,
        height: isMobile ? 30 : 41 * Common.WIDTH_DIVISOR,
    },
    loginContainer: {
        justifyContent: "center",
    },
    loginText: {
        fontSize: isMobile ? 16 : 20,
        fontWeight: "600",
        color: "#656464",
    },
    modalOverlay: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    dropdown: {
        marginTop: isMobile ? 60 : 70,
        marginRight: isMobile ? 10 : 40,
        width: isMobile ? 120 : 140 * Common.WIDTH_DIVISOR,
        backgroundColor: 'white',
        borderRadius: 4,
        shadowColor: "rgba(0, 0, 0, 0.25)",
        shadowOffset: { width: 0, height: 4 },
        shadowRadius: 4,
        shadowOpacity: 1,
        elevation: 5,
    },
    dropdownItem: {
        flexDirection: "row",
        alignItems: "center",
        padding: 10,
    },
    dropdownText: {
        fontSize: isMobile ? 14 : 16 * Common.WIDTH_DIVISOR,
        fontWeight: "400",
        color: "#000000",
    },
    profileModalOverlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    profileModal: {
        width: isMobile ? '90%' : 1633 * Common.WIDTH_DIVISOR,
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
    },
    profileTitle: {
        fontSize: isMobile ? 18 : 20 * Common.WIDTH_DIVISOR,
        fontWeight: "bold",
        marginBottom: 20,
    },
    profileItem: {
        flexDirection: isMobile ? 'column' : 'row',
        marginBottom: 15,
    },
    profileLabel: {
        width: isMobile ? '100%' : '30%',
        fontWeight: 'bold',
        marginBottom: isMobile ? 5 : 0,
    },
    profileValue: {
        width: isMobile ? '100%' : '70%',
        borderWidth: 1,
        borderColor: "rgba(151, 151, 151, 1.0)",
        borderRadius: 4,
        padding: 10,
    },
    backButton: {
        marginTop: 20,
        alignSelf: 'center',
    },
});