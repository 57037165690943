import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, TouchableOpacity, Modal, TextInput, StyleSheet, Image, Platform, ScrollView, Dimensions } from 'react-native';
import { getProductList, createProduct, updateProduct, deleteProduct, toggleProductSale, getCategoryList } from '../Util/api';
import { CustomButton, CustomTextInput, CustomTitle, CustomText, CustomPicker } from './WebComponent';
import Common from '../CommonStyles/common';
import WebAlert from './Alert';
import * as ImagePicker from 'expo-image-picker';
import { API_BASE_URL } from '../Util/api';

const { width } = Dimensions.get('window');
const isMobile = width < 768;

export default function ProductManager() {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [originPrice, setOriginPrice] = useState('');
    const [stock, setStock] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [images, setImages] = useState([]);

    useEffect(() => {
        fetchProducts();
        fetchCategories();
    }, []);

    const fetchProducts = async () => {
        try {
            const data = await getProductList();
            setProducts(data.products);
        } catch (error) {
            console.error('Failed to fetch products:', error);
        }
    };

    const fetchCategories = async () => {
        try {
            const data = await getCategoryList();
            setCategories(data.categories);
        } catch (error) {
            console.error('Failed to fetch categories:', error);
        }
    };

    const handleAddProduct = async () => {
        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
            formData.append('price', price);
            formData.append('originPrice', originPrice);
            formData.append('stock', stock);
            formData.append('category', categoryId);

            for (let i = 0; i < images.length; i++) {
                const response = await fetch(images[i]);
                const blob = await response.blob();
                formData.append('images', blob, `image${i}.jpg`);
            }

            await createProduct(formData);
            fetchProducts();
            setModalVisible(false);
        } catch (error) {
            console.error('Failed to add product:', error);
        }
    };

    const handleUpdateProduct = async () => {
        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
            formData.append('price', price);
            formData.append('originPrice', originPrice);
            formData.append('stock', stock);
            formData.append('category', categoryId);

            // 检查图片是否发生变化
            let imagesChanged = false;
            if (images.length !== currentProduct.images.length) {
                imagesChanged = true;
            } else {
                for (let i = 0; i < images.length; i++) {
                    if (images[i] !== `${API_BASE_URL.replace('/lmback', '')}${currentProduct.images[i]}`) {
                        imagesChanged = true;
                        break;
                    }
                }
            }

            // 只有在图片发生变化时才添加新的图片数据
            if (imagesChanged) {
                for (let i = 0; i < images.length; i++) {
                    const response = await fetch(images[i]);
                    const blob = await response.blob();
                    formData.append('images', blob, `image${i}.jpg`);
                }
            } else {
                // 如果图片没有变化，我们可以发送一个标志告诉后端不需要更新图片
                formData.append('imagesUnchanged', 'true');
            }

            await updateProduct(currentProduct._id, formData);
            fetchProducts();
            setModalVisible(false);
        } catch (error) {
            console.error('Failed to update product:', error);
        }
    };

    const handleDeleteProduct = async (id) => {
        WebAlert(
            "",
            "Are you sure you want to delete this product?",
            [
                {
                    text: "Cancel",
                    onPress: () => console.log("Cancel delete"),
                    style: "cancel"
                },
                {
                    text: "Confirm",
                    onPress: async () => {
                        try {
                            await deleteProduct(id);
                            fetchProducts();
                        } catch (error) {
                            console.error('Failed to delete product:', error);
                        }
                    }
                }
            ],
            { cancelable: false }
        );
    };

    const handleToggleSale = async (id) => {
        try {
            await toggleProductSale(id);
            fetchProducts();
        } catch (error) {
            console.error('Failed to toggle product sale:', error);
        }
    };

    const pickImage = async () => {
        if (Platform.OS === 'web') {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.multiple = true;
            input.onchange = async (e) => {
                const files = Array.from(e.target.files);
                const newImages = await Promise.all(files.map(file => {
                    return new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            const img = new window.Image();
                            img.src = event.target.result;
                            img.onload = () => {
                                const canvas = document.createElement('canvas');
                                const ctx = canvas.getContext('2d');
                                const maxSize = 1000;
                                let width = img.width;
                                let height = img.height;

                                if (width > height) {
                                    if (width > maxSize) {
                                        height *= maxSize / width;
                                        width = maxSize;
                                    }
                                } else {
                                    if (height > maxSize) {
                                        width *= maxSize / height;
                                        height = maxSize;
                                    }
                                }

                                canvas.width = width;
                                canvas.height = height;
                                ctx.drawImage(img, 0, 0, width, height);

                                canvas.toBlob((blob) => {
                                    const imageUrl = URL.createObjectURL(blob);
                                    resolve({ blob, url: imageUrl });
                                }, 'image/jpeg', 0.7);
                            };
                        };
                        reader.readAsDataURL(file);
                    });
                }));
                setImages(prevImages => [...prevImages, ...newImages.map(img => img.url)]);
            };
            input.click();
        } else {
            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsMultipleSelection: true,
                aspect: [4, 3],
                quality: 1,
            });

            if (!result.cancelled) {
                const newImages = result.selected ? result.selected.map(item => item.uri) : [result.uri];
                setImages(prevImages => [...prevImages, ...newImages]);
            }
        }
    };

    const removeImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    const renderProductItem = ({ item }) => {
        if (isMobile) {
            return (
                <View style={styles.mobileProductItem}>
                    <ScrollView horizontal style={styles.mobileImageScrollView}>
                        {item.images && item.images.map((imageUrl, index) => (
                            <Image
                                key={index}
                                source={{ uri: `${API_BASE_URL.replace('/lmback', '')}${imageUrl}` }}
                                style={styles.mobileProductImage}
                            />
                        ))}
                    </ScrollView>
                    <View style={styles.mobileProductDetails}>
                        <CustomText title={`名称: ${item.name}`} style={styles.mobileProductText} />
                        <CustomText title={`类别: ${item.category ? item.category.name : '无'}`} style={styles.mobileProductText} />
                        <CustomText title={`价格: $${item.price}`} style={styles.mobileProductText} />
                        <CustomText title={`原价: $${item.originPrice ? item.originPrice : 0}`} style={styles.mobileProductText} />
                        <CustomText title={`库存: ${item.stock}`} style={styles.mobileProductText} />
                        <CustomText title={`状态: ${item.isOnSale ? "在售" : "下架"}`} style={styles.mobileProductText} />
                    </View>
                    <View style={styles.mobileActionButtons}>
                        <CustomButton
                            title="编辑"
                            onPress={() => {
                                setCurrentProduct(item);
                                setName(item.name);
                                setDescription(item.description);
                                setPrice(item.price.toString());
                                setOriginPrice(item.originPrice ? item.originPrice.toString() : '');
                                setStock(item.stock.toString());
                                setCategoryId(item.category ? item.category._id : '');
                                setImages(item.images.map(img => `${API_BASE_URL.replace('/lmback', '')}${img}`));
                                setModalVisible(true);
                            }}
                            style={styles.mobileActionButton}
                        />
                        <CustomButton
                            title="删除"
                            onPress={() => handleDeleteProduct(item._id)}
                            style={styles.mobileActionButton}
                        />
                        <CustomButton
                            title={item.isOnSale ? "下架" : "上架"}
                            onPress={() => handleToggleSale(item._id)}
                            style={styles.mobileActionButton}
                        />
                    </View>
                </View>
            );
        } else {
            return (
                <View style={styles.desktopProductItem}>
                    <ScrollView horizontal style={{ width: "18%", height: 100 }}>
                        {item.images && item.images.map((imageUrl, index) => (
                            <Image
                                key={index}
                                source={{ uri: `${API_BASE_URL.replace('/lmback', '')}${imageUrl}` }}
                                style={styles.productImage}
                            />
                        ))}
                    </ScrollView>
                    <CustomText title={item.name} width="13%" />
                    <CustomText title={item.category ? item.category.name : 'None'} width="13%" />
                    <CustomText title={`$${item.price}`} width="8%" />
                    <CustomText title={`$${item.originPrice ? item.originPrice : 0}`} width="6%" />
                    <CustomText title={item.stock.toString()} width="8%" />
                    <CustomText title={item.isOnSale ? "On" : "OFF"} width="9%" />
                    <View style={styles.actionButtons}>
                        <CustomButton
                            title="Edit"
                            onPress={() => {
                                setCurrentProduct(item);
                                setName(item.name);
                                setDescription(item.description);
                                setPrice(item.price.toString());
                                setOriginPrice(item.originPrice ? item.originPrice.toString() : '');
                                setStock(item.stock.toString());
                                setCategoryId(item.category ? item.category._id : '');
                                setImages(item.images.map(img => `${API_BASE_URL.replace('/lmback', '')}${img}`));
                                setModalVisible(true);
                            }}
                            style={styles.actionButton}
                        />
                        <CustomButton
                            title="Delete"
                            onPress={() => handleDeleteProduct(item._id)}
                            style={styles.actionButton}
                        />
                        <CustomButton
                            title={item.isOnSale ? "Take Off" : "Put On Sale"}
                            onPress={() => handleToggleSale(item._id)}
                            style={{ ...styles.actionButton, width: 100 }}
                        />
                    </View>
                </View>
            );
        }
    };

    return (
        <View style={styles.container}>
            <CustomButton
                title="Add Product"
                onPress={() => {
                    setCurrentProduct(null);
                    setName('');
                    setDescription('');
                    setPrice('');
                    setStock('');
                    setCategoryId('');
                    setImages([]);
                    setModalVisible(true);
                }}
                style={styles.addButton}
            />
            {!isMobile && (
                <View style={styles.headerRow}>
                    <CustomTitle title="Image" width="18%" />
                    <CustomTitle title="Name" width="13%" />
                    <CustomTitle title="Category" width="13%" />
                    <CustomTitle title="Price" width="8%" />
                    <CustomTitle title="Origin Price" width="6%" />
                    <CustomTitle title="Stock" width="8%" />
                    <CustomTitle title="Status" width="9%" />
                    <CustomTitle title="Operation" width="20%" />
                </View>
            )}
            <FlatList
                data={products}
                renderItem={renderProductItem}
                keyExtractor={item => item._id}
                style={styles.list}
            />
            <Modal
                visible={modalVisible}
                onRequestClose={() => setModalVisible(false)}
                transparent={true}
            >
                <View style={styles.modalBackground}>
                    <ScrollView contentContainerStyle={styles.modalContent}>
                        <CustomTextInput
                            title="Name"
                            value={name}
                            onChangeText={setName}
                            style={styles.modalInput}
                        />

                        <View style={styles.descriptionContainer}>
                            <Text style={styles.descriptionTitle}>Description</Text>
                            <TextInput
                                style={styles.descriptionInput}
                                value={description}
                                onChangeText={setDescription}
                                multiline={true}
                                numberOfLines={5}
                            />
                        </View>
                        <CustomTextInput
                            title="Current Price"
                            value={price}
                            onChangeText={setPrice}
                            keyboardType="numeric"
                            style={styles.modalInput}
                        />
                        <CustomTextInput
                            title="Origin Price"
                            value={originPrice}
                            onChangeText={setOriginPrice}
                            keyboardType="numeric"
                            style={styles.modalInput}
                        />
                        <CustomTextInput
                            title="Stock"
                            value={stock}
                            onChangeText={setStock}
                            keyboardType="numeric"
                            style={styles.modalInput}
                        />
                        <CustomPicker
                            title="Category"
                            selectedValue={categoryId}
                            onValueChange={(itemValue) => setCategoryId(itemValue)}
                            items={categories.map(cat => ({ label: cat.name, value: cat._id }))}
                            style={styles.modalPicker}
                        />
                        <ScrollView horizontal style={styles.imageContainer}>
                            {images.map((image, index) => (
                                <View key={index} style={styles.imageWrapper}>
                                    <Image source={{ uri: image }} style={styles.previewImage} />
                                    <TouchableOpacity onPress={() => removeImage(index)} style={styles.removeButton}>
                                        <CustomText title="X" />
                                    </TouchableOpacity>
                                </View>
                            ))}
                            <TouchableOpacity onPress={pickImage} style={styles.addImageButton}>
                                <CustomText title="+" />
                            </TouchableOpacity>
                        </ScrollView>
                        <View style={styles.modalButtons}>
                            <CustomButton
                                title={currentProduct ? "Update Product" : "Add Product"}
                                onPress={currentProduct ? handleUpdateProduct : handleAddProduct}
                                style={styles.modalButton}
                            />
                            <CustomButton
                                title="Cancel"
                                onPress={() => setModalVisible(false)}
                                style={styles.modalButton}
                            />
                        </View>
                    </ScrollView>
                </View>
            </Modal>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: isMobile ? 10 : 20,
    },
    addButton: {
        width: isMobile ? '100%' : 120,
        alignSelf: 'flex-start',
        marginBottom: 20,
        marginTop: isMobile ? 50 : 0,
    },
    headerRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#ccc',
        paddingBottom: 10,
        marginBottom: 10,
    },
    list: {
        flex: 1,
    },
    desktopProductItem: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    mobileProductItem: {
        flexDirection: 'column',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        backgroundColor: '#fff',
        borderRadius: 8,
        marginBottom: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        elevation: 4,
    },
    productImage: {
        width: 100,
        height: 100,
        marginRight: 10,
    },
    mobileImageScrollView: {
        width: '100%',
        height: 100,
        marginBottom: 10,
    },
    mobileProductImage: {
        width: 100,
        height: 100,
        marginRight: 10,
        borderRadius: 8,
    },
    mobileProductDetails: {
        marginBottom: 10,
    },
    mobileProductText: {
        marginBottom: 5,
    },
    actionButtons: {
        flexDirection: 'row',
        width: '25%',
        justifyContent: 'center',
    },
    mobileActionButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    actionButton: {
        marginLeft: 10,
    },
    mobileActionButton: {
        flex: 1,
        marginHorizontal: 5,
    },
    modalBackground: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        width: isMobile ? '90%' : '100%',
        maxWidth: 500,
    },
    modalInput: {
        width: '100%',
        marginBottom: 15,
    },
    descriptionContainer: {
        width: '100%',
        marginBottom: 15,
    },
    descriptionTitle: {
        fontSize: 16,
        fontWeight: "500",
        marginBottom: 5,
        color: "#000000",
    },
    descriptionInput: {
        width: '100%',
        height: 100,
        borderColor: '#1886a8',
        borderWidth: 1,
        borderRadius: 4,
        padding: 10,
        textAlignVertical: 'top',
    },
    modalPicker: {
        width: '100%',
        marginBottom: 15,
    },
    imageContainer: {
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 15,
    },
    imageWrapper: {
        marginRight: 10,
        position: 'relative',
    },
    previewImage: {
        width: 80,
        height: 80,
        borderRadius: 5,
    },
    removeButton: {
        position: 'absolute',
        top: 5,
        right: 5,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderRadius: 10,
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    addImageButton: {
        width: 80,
        height: 80,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 20,
    },
    modalButton: {
        width: 120,
    },
});