import React, { useEffect, useState } from 'react';
import { View, ScrollView, FlatList, TouchableWithoutFeedback, Picker, Modal, Button, Text, TextInput, Image, TouchableOpacity, StyleSheet, Dimensions } from "react-native";
import Common from '../CommonStyles/common';

const { width } = Dimensions.get('window');
const isMobile = width < 768;

export const WebModal = ({ visible, onClose, children }) => {
    return (
        <Modal
            transparent={true}
            visible={visible}
        >
            <TouchableWithoutFeedback onPress={onClose}>
                <ScrollView style={{
                    flex: 1,
                    backgroundColor: 'rgba(0,0,0,0.5)',

                }} contentContainerStyle={{ alignItems: 'center', }}>
                    <TouchableWithoutFeedback >
                        {children}
                    </TouchableWithoutFeedback>
                </ScrollView>
            </TouchableWithoutFeedback>
        </Modal>
    );
}
export const Pagination = ({ currentPage, totalItems, totalPages, itemsPerPage, onPageChange }) => {
    const [inputPage, setInputPage] = useState(currentPage ? currentPage.toString() : "");

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            onPageChange(page);
            setInputPage(page.toString());
        }
    };

    const handleInputChange = (text) => {
        setInputPage(text);
    };

    const handleInputSubmit = () => {
        const page = parseInt(inputPage);
        if (page > 0 && page <= totalPages) {
            onPageChange(page);
        } else {
            setInputPage(currentPage.toString());
        }
    };

    return (
        <View style={styles.paginationContainer}>
            <Text style={styles.pageInfo}>总计: {totalItems} 项 / {totalPages} 页</Text>
            <View style={styles.paginationControls}>
                <TouchableOpacity onPress={() => handlePageChange(1)} style={styles.pageButton}>
                    <Text style={styles.pageButtonText}>首页</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handlePageChange(currentPage - 1)} style={styles.pageButton}>
                    <Text style={styles.pageButtonText}>上一页</Text>
                </TouchableOpacity>
                <View style={styles.pageInputContainer}>
                    <TextInput
                        style={styles.pageInput}
                        value={inputPage}
                        onChangeText={handleInputChange}
                        onSubmitEditing={handleInputSubmit}
                        keyboardType="numeric"
                    />
                    <Text style={styles.pageText}>/ {totalPages}</Text>
                </View>
                <TouchableOpacity onPress={() => handlePageChange(currentPage + 1)} style={styles.pageButton}>
                    <Text style={styles.pageButtonText}>下一页</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handlePageChange(totalPages)} style={styles.pageButton}>
                    <Text style={styles.pageButtonText}>末页</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
};

export const SearchBar = ({ searchData, onSearch, placeholder }) => {
    const [searchTerm, setSearchTerm] = useState(searchData.code);
    const [status, setStatus] = useState('Choose');
    const [startDate, setStartDate] = useState(searchData.startDate);
    const [endDate, setEndDate] = useState(searchData.endDate);

    const handleSearch = () => {
        onSearch(searchTerm, startDate + " 00:00:00", endDate + " 23:59:59", status);
    };

    return (
        <View style={{
            marginLeft: 31 * Common.WIDTH_DIVISOR,
            flexDirection: 'row',
            alignItems: 'center',
            padding: 10 * Common.WIDTH_DIVISOR,
            marginTop: 10 * Common.WIDTH_DIVISOR,
        }}>
            <View style={{
                width: 119 * Common.WIDTH_DIVISOR,
                height: 41 * Common.WIDTH_DIVISOR,
                backgroundColor: "#FFFFFF",
                borderRadius: 4,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "rgba(24, 134, 168, 1.0)",
                flexDirection: "row",
                alignItems: "center",
            }}>
                <Image style={{
                    marginLeft: 10 * Common.WIDTH_DIVISOR,
                    width: 16 * Common.WIDTH_DIVISOR,
                    height: 16 * Common.WIDTH_DIVISOR,
                }} source={require("../../resource/Web/person.png")} />
                <Text style={{
                    marginLeft: 13 * Common.WIDTH_DIVISOR,
                    fontFamily: "Inter",
                    fontSize: 16 * Common.WIDTH_DIVISOR,
                    fontWeight: "400",
                    textAlign: "center",
                    color: "#1886A8"
                }}>Add User</Text>
            </View>

            {/* <TextInput
                style={{
                    borderStyle: "solid",
                    borderColor: "#d2d2d2",
                    borderWidth: 1,
                    borderRadius: 5,
                    padding: 5,
                    marginRight: 10 * Common.WIDTH_DIVISOR,
                    fontSize: 15 * Common.WIDTH_DIVISOR,
                    color: "#8b8b8b",
                }}
                placeholder={placeholder}
                value={searchTerm}
                onChangeText={text => setSearchTerm(text)}
            /> */}
            {/* <Text style={{ marginRight: 10 }}>{"Order Status："}</Text>
            <Picker
                selectedValue={status}
                style={{
                    flex: 1,
                    marginRight: 10
                }}
                onValueChange={(itemValue) => setStatus(itemValue)}
            >
                <Picker.Item label="Choose" value="Choose" />
                <Picker.Item label="待处理" value="待处理" />
                <Picker.Item label="进行中" value="进行中" />
                <Picker.Item label="已完成" value="已完成" />
            </Picker> */}
            <Text style={{
                fontSize: 18 * Common.WIDTH_DIVISOR,
                color: "#000000",
                fontWeight: "400",
                marginRight: 10, marginLeft: 748 * Common.WIDTH_DIVISOR
            }}>{"Date："}</Text>
            <input
                style={styles.dateInput}
                type="date"
                value={startDate}
                onChange={text => {
                    console.log(text)
                    console.log(text.target.value)
                    setStartDate(text.target.value)
                }}
            />
            <Text style={{ marginRight: 10 }}>{" - "}</Text>
            <input
                style={styles.dateInput}
                type="date"
                value={endDate}
                onChange={text => {
                    console.log(text.target.value)
                    setEndDate(text.target.value)
                }}
            />
            {/* <TextInput
                style={styles.dateInput}
                value={startDate}
                onChangeText={text => setStartDate(text)}
            />
            <TextInput
                style={styles.dateInput}
                value={endDate}
                onChangeText={text => setEndDate(text)}
            /> */}
            <TouchableOpacity onPress={handleSearch}>
                <View style={{
                    marginLeft: 10 * Common.WIDTH_DIVISOR,
                    borderRadius: 4,
                    backgroundColor: "#108693",
                    shadowColor: "rgba(0, 0, 0, 0.25)",
                    shadowOffset: {
                        width: 0,
                        height: 4
                    },
                    shadowRadius: 4,
                    shadowOpacity: 1,
                    width: 65 * Common.WIDTH_DIVISOR,
                    height: 30 * Common.WIDTH_DIVISOR,
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Text style={{
                        fontSize: 16 * Common.WIDTH_DIVISOR,
                        fontWeight: "500",
                        color: "#fff",
                    }}>Search</Text>
                </View></TouchableOpacity>
            {/* <Button title="Go" onPress={handleSearch} /> */}
        </View >
    );
};


export const SearchBarUser = ({ searchData, onSearch, onPress, placeholder }) => {
    const [searchTerm, setSearchTerm] = useState(searchData.code);
    const [status, setStatus] = useState('Choose');
    const [startDate, setStartDate] = useState(searchData.startDate);
    const [endDate, setEndDate] = useState(searchData.endDate);
    const [suspend, setSuspend] = useState(searchData.suspend);
    const [email, setEmail] = useState(searchData.email);
    const [phone, setPhone] = useState(searchData.phone);

    const handleSearch = () => {
        onSearch(searchTerm, startDate + " 00:00:00", endDate + " 23:59:59", status, suspend, email, phone);
    };

    return (
        <View style={{
            marginLeft: 31 * Common.WIDTH_DIVISOR,
            flexDirection: 'row',
            alignItems: 'center',
            padding: 10 * Common.WIDTH_DIVISOR,
            marginTop: 10 * Common.WIDTH_DIVISOR,
        }}>
            {/* <TouchableOpacity onPress={onPress}>
                <View style={{
                    width: 119 * Common.WIDTH_DIVISOR,
                    height: 41 * Common.WIDTH_DIVISOR,
                    backgroundColor: "#FFFFFF",
                    borderRadius: 4,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "rgba(24, 134, 168, 1.0)",
                    flexDirection: "row",
                    alignItems: "center",
                }}>
                    <Image style={{
                        marginLeft: 10 * Common.WIDTH_DIVISOR,
                        width: 16 * Common.WIDTH_DIVISOR,
                        height: 16 * Common.WIDTH_DIVISOR,
                    }} source={require("../../resource/Web/person.png")} />
                    <Text style={{
                        marginLeft: 13 * Common.WIDTH_DIVISOR,
                        fontFamily: "Inter",
                        fontSize: 16 * Common.WIDTH_DIVISOR,
                        fontWeight: "400",
                        textAlign: "center",
                        color: "#1886A8"
                    }}>Add User</Text>
                </View>
            </TouchableOpacity> */}
            {/* <TextInput
                style={{
                    marginLeft: 300 * Common.WIDTH_DIVISOR,
                    borderStyle: "solid",
                    width: 180 * Common.WIDTH_DIVISOR,
                    paddingLeft: 10 * Common.WIDTH_DIVISOR,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "rgba(151, 151, 151, 1.0)",
                }}
                value={phone}
                onChangeText={text => setPhone(text)}
                placeholder='Phone'
                placeholderTextColor={"#9C9C9C"}
            /> */}
            <TextInput
                style={{
                    marginLeft: 10 * Common.WIDTH_DIVISOR,
                    borderStyle: "solid",
                    width: 180 * Common.WIDTH_DIVISOR,
                    paddingLeft: 10 * Common.WIDTH_DIVISOR,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "rgba(151, 151, 151, 1.0)",
                }}
                value={email}
                onChangeText={text => setEmail(text)}
                placeholder='Email'
                placeholderTextColor={"#9C9C9C"}
            />

            {/* <Text style={{
                marginLeft: 10 * Common.WIDTH_DIVISOR,
                ...styles.pickerTitle,
            }}>{"Forbidden:  "}</Text>
            <Picker
                selectedValue={suspend}
                style={{
                    ...styles.pickerContent,
                    width: 80 * Common.WIDTH_DIVISOR,
                }}
                onValueChange={(itemValue) => setSuspend(itemValue)}
            >
                <Picker.Item label="Choose" value={2} />
                <Picker.Item label="No" value={0} />
                <Picker.Item label="Yes" value={1} />
            </Picker> */}
            {/* <TextInput
                style={{
                    borderStyle: "solid",
                    borderColor: "#d2d2d2",
                    borderWidth: 1,
                    borderRadius: 5,
                    padding: 5,
                    marginRight: 10 * Common.WIDTH_DIVISOR,
                    fontSize: 15 * Common.WIDTH_DIVISOR,
                    color: "#8b8b8b",
                }}
                placeholder={placeholder}
                value={searchTerm}
                onChangeText={text => setSearchTerm(text)}
            /> */}
            {/* <Text style={{ marginRight: 10 }}>{"Order Status："}</Text>
            <Picker
                selectedValue={status}
                style={{
                    flex: 1,
                    marginRight: 10
                }}
                onValueChange={(itemValue) => setStatus(itemValue)}
            >
                <Picker.Item label="Choose" value="Choose" />
                <Picker.Item label="待处理" value="待处理" />
                <Picker.Item label="进行中" value="进行中" />
                <Picker.Item label="已完成" value="已完成" />
            </Picker> */}
            <Text style={{
                fontSize: 18 * Common.WIDTH_DIVISOR,
                color: "#000000",
                fontWeight: "400",
                marginRight: 10, marginLeft: 20 * Common.WIDTH_DIVISOR
            }}>{"Date："}</Text>
            <input
                style={styles.dateInput}
                type="date"
                value={startDate}
                onChange={text => {
                    console.log(text)
                    console.log(text.target.value)
                    setStartDate(text.target.value)
                }}
            />
            <Text style={{ marginRight: 10 }}>{" - "}</Text>
            <input
                style={styles.dateInput}
                type="date"
                value={endDate}
                onChange={text => {
                    console.log(text.target.value)
                    setEndDate(text.target.value)
                }}
            />
            {/* <TextInput
                style={styles.dateInput}
                value={startDate}
                onChangeText={text => setStartDate(text)}
            />
            <TextInput
                style={styles.dateInput}
                value={endDate}
                onChangeText={text => setEndDate(text)}
            /> */}
            <TouchableOpacity onPress={handleSearch}>
                <View style={{
                    marginLeft: 10 * Common.WIDTH_DIVISOR,
                    borderRadius: 4,
                    backgroundColor: "#108693",
                    shadowColor: "rgba(0, 0, 0, 0.25)",
                    shadowOffset: {
                        width: 0,
                        height: 4
                    },
                    shadowRadius: 4,
                    shadowOpacity: 1,
                    width: 65 * Common.WIDTH_DIVISOR,
                    height: 30 * Common.WIDTH_DIVISOR,
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Text style={{
                        fontSize: 16 * Common.WIDTH_DIVISOR,
                        fontWeight: "500",
                        color: "#fff",
                    }}>Search</Text>
                </View></TouchableOpacity>
            {/* <Button title="Go" onPress={handleSearch} /> */}
        </View >
    );
};


export const SearchBarAdmin = ({ searchData, onSearch, onPress, placeholder }) => {
    const [searchTerm, setSearchTerm] = useState(searchData.code);
    const [status, setStatus] = useState('Choose');
    const [startDate, setStartDate] = useState(searchData.startDate);
    const [endDate, setEndDate] = useState(searchData.endDate);
    const [suspend, setSuspend] = useState(searchData.suspend);
    const handleSearch = () => {
        onSearch(searchTerm, startDate + " 00:00:00", endDate + " 23:59:59", status, suspend);
    };

    return (
        <View style={{
            marginLeft: 31 * Common.WIDTH_DIVISOR,
            flexDirection: 'row',
            alignItems: 'center',
            padding: 10 * Common.WIDTH_DIVISOR,
            marginTop: 10 * Common.WIDTH_DIVISOR,
        }}>
            <TouchableOpacity onPress={onPress}>
                <View style={{
                    width: 200 * Common.WIDTH_DIVISOR,
                    height: 41 * Common.WIDTH_DIVISOR,
                    backgroundColor: "#FFFFFF",
                    borderRadius: 4,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "rgba(24, 134, 168, 1.0)",
                    flexDirection: "row",
                    alignItems: "center",
                }}>
                    <Image style={{
                        marginLeft: 10 * Common.WIDTH_DIVISOR,
                        width: 16 * Common.WIDTH_DIVISOR,
                        height: 16 * Common.WIDTH_DIVISOR,
                    }} source={require("../../resource/Web/person.png")} />
                    <Text style={{
                        marginLeft: 13 * Common.WIDTH_DIVISOR,
                        fontFamily: "Inter",
                        fontSize: 16 * Common.WIDTH_DIVISOR,
                        fontWeight: "400",
                        textAlign: "center",
                        color: "#1886A8"
                    }}>Add Administrator</Text>
                </View>
            </TouchableOpacity>


            <Text style={{
                marginLeft: 600 * Common.WIDTH_DIVISOR,
                fontSize: 18 * Common.WIDTH_DIVISOR,
                color: "#000000",
                fontWeight: "400",
                marginRight: 10,
            }}>{"Date："}</Text>
            <input
                style={styles.dateInput}
                type="date"
                value={startDate}
                onChange={text => {
                    console.log(text)
                    console.log(text.target.value)
                    setStartDate(text.target.value)
                }}
            />
            <Text style={{ marginRight: 10 }}>{" - "}</Text>
            <input
                style={styles.dateInput}
                type="date"
                value={endDate}
                onChange={text => {
                    console.log(text.target.value)
                    setEndDate(text.target.value)
                }}
            />
            {/* <TextInput
                style={styles.dateInput}
                value={startDate}
                onChangeText={text => setStartDate(text)}
            />
            <TextInput
                style={styles.dateInput}
                value={endDate}
                onChangeText={text => setEndDate(text)}
            /> */}
            <TouchableOpacity onPress={handleSearch}>
                <View style={{
                    marginLeft: 10 * Common.WIDTH_DIVISOR,
                    borderRadius: 4,
                    backgroundColor: "#108693",
                    shadowColor: "rgba(0, 0, 0, 0.25)",
                    shadowOffset: {
                        width: 0,
                        height: 4
                    },
                    shadowRadius: 4,
                    shadowOpacity: 1,
                    width: 65 * Common.WIDTH_DIVISOR,
                    height: 30 * Common.WIDTH_DIVISOR,
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Text style={{
                        fontSize: 16 * Common.WIDTH_DIVISOR,
                        fontWeight: "500",
                        color: "#fff",
                    }}>Search</Text>
                </View></TouchableOpacity>
            {/* <Button title="Go" onPress={handleSearch} /> */}
        </View >
    );
};



export const RelativeTime = ({ datetimeString }) => {
    const getRelativeTime = (date) => {
        const now = new Date();
        const past = new Date(date);
        const secondsAgo = (now - past) / 1000;

        if (secondsAgo < 60) return 'just now';
        if (secondsAgo < 3600) return `${Math.floor(secondsAgo / 60)} minutes ago`;
        if (secondsAgo < 86400) return `${Math.floor(secondsAgo / 3600)} hours ago`;
        if (secondsAgo < 604800) return `${Math.floor(secondsAgo / 86400)} days ago`;

        return new Date(date).toLocaleDateString();
    };

    return (
        <View>
            <Text>{getRelativeTime(datetimeString)}</Text>
        </View>
    );
}


export const RelativeTimeString = (datetimeString) => {
    const now = new Date();
    const past = new Date(datetimeString);
    const secondsAgo = (now - past) / 1000;
    if (secondsAgo < 60) return 'just now';
    if (secondsAgo < 3600) return `${Math.floor(secondsAgo / 60)} minutes ago`;
    if (secondsAgo < 86400) return `${Math.floor(secondsAgo / 3600)} hours ago`;
    if (secondsAgo < 604800) return `${Math.floor(secondsAgo / 86400)} days ago`;

    return new Date(datetimeString).toLocaleDateString();
}


export const CustomTitle = ({ title, width }) => (
    <View style={{ width: width, justifyContent: "center", alignItems: "center" }}>
        <Text style={{
            fontSize: 16 * Common.WIDTH_DIVISOR,
            fontWeight: "400",
            fontStyle: "normal",
            color: "#1886A8"
        }}>{title}</Text>
    </View>
);

export const CustomText = ({ title, width, style }) => (
    <View style={{ ...style, width: width, justifyContent: "center", alignItems: "center" }}>
        <Text style={{
            fontSize: 16 * Common.WIDTH_DIVISOR,
            fontWeight: "400",
            fontStyle: "normal",
            color: "#000000",
        }} ellipsizeMode='tail'
            numberOfLines={1} >{title}</Text>
    </View>
);

export const CustomTitle1 = ({ title, style }) => (
    <View style={[{
        borderRadius: 4 * Common.WIDTH_DIVISOR,
        width: 77 * Common.WIDTH_DIVISOR,
        height: 21 * Common.WIDTH_DIVISOR,
        backgroundColor: "#108693",
        justifyContent: "center", alignItems: "center"
    }, style
    ]}>
        <Text style={{
            fontSize: 12 * Common.WIDTH_DIVISOR,
            fontWeight: "400",
            color: "#fff"
        }}>{title}</Text>
    </View>
);

export const CustomText1 = ({ title, value, style, width = 300 * Common.WIDTH_DIVISOR }) => (
    <View style={[{
        width: width,
    }, style]}>
        <View style={{ flexDirection: "row", }}>
            <Text style={{
                color: "#000000",
                fontSize: 12 * Common.WIDTH_DIVISOR,
            }}>{title}: </Text>
            <Text style={{
                marginLeft: 30 * Common.WIDTH_DIVISOR,
                color: "#000000",
                fontSize: 12 * Common.WIDTH_DIVISOR,
            }}>{value}</Text>
        </View>
        {/* 
        <View style={{
            marginTop: 14 * Common.WIDTH_DIVISOR,
            marginLeft: 50 * Common.WIDTH_DIVISOR,
            backgroundColor: "#979797",
            height: 1,
            width: width - 50 * Common.WIDTH_DIVISOR,
        }}></View> */}
    </View>
);


export const CustomText2 = ({ title, value, style, width = 300 * Common.WIDTH_DIVISOR }) => (
    <View style={[{
        width: width,
    }, style]}>
        <View style={{ flexDirection: "row", }}>
            <Text style={{
                color: "#000000",
                fontSize: 12 * Common.WIDTH_DIVISOR,
            }}>{title}: </Text>
            <ScrollView>
                <Text style={{
                    height: 150 * Common.WIDTH_DIVISOR,
                    marginLeft: 30 * Common.WIDTH_DIVISOR,
                    color: "#000000",
                    fontSize: 12 * Common.WIDTH_DIVISOR,
                }}>{value}</Text>
            </ScrollView>
        </View>

        <View style={{
            marginTop: 14 * Common.WIDTH_DIVISOR,
            marginLeft: 50 * Common.WIDTH_DIVISOR,
            backgroundColor: "#979797",
            height: 1,
            width: width - 50 * Common.WIDTH_DIVISOR,
        }}></View>
    </View>
);


export const CustomButton = ({ title, onPress, style }) => (
    <TouchableOpacity onPress={onPress} style={{
        justifyContent: "center",
        alignItems: "center",
    }}>
        <View style={[{
            width: isMobile ? '100%' : 90 * Common.WIDTH_DIVISOR,
            height: 28 * Common.WIDTH_DIVISOR,
            borderRadius: 4 * Common.WIDTH_DIVISOR,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "rgba(24, 134, 168, 1.0)",
            justifyContent: "center",
            alignItems: "center",
        }, style]}>
            <Text style={{
                fontFamily: "Inter",
                fontSize: isMobile ? 14 : 16 * Common.WIDTH_DIVISOR,
                fontWeight: "400",
                fontStyle: "normal",
                color: "#108693",
            }}>{title}</Text>
        </View>
    </TouchableOpacity>
);

export const CustomButton1 = ({ title, onPress, style }) => (
    <TouchableOpacity onPress={onPress} style={{
        justifyContent: "center",
        alignItems: "center",
    }}>
        <View style={[{
            backgroundColor: "#108693",
            width: 74 * Common.WIDTH_DIVISOR,
            height: 28 * Common.WIDTH_DIVISOR,
            borderRadius: 4 * Common.WIDTH_DIVISOR,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "rgba(24, 134, 168, 1.0)",
            justifyContent: "center",
            alignItems: "center",
        }, style]}>
            <Text style={{
                fontFamily: "Inter",
                fontSize: 12 * Common.WIDTH_DIVISOR,
                fontWeight: "400",
                fontStyle: "normal",
                color: "#fff",
            }}>{title}</Text>
        </View>
    </TouchableOpacity>


);



export const CustomTextInput = ({ title, style, value, placeholder, onChangeText, editable = true }) => (
    <View style={[{
        borderRadius: 4 * Common.WIDTH_DIVISOR,
        width: isMobile ? '100%' : 368 * Common.WIDTH_DIVISOR,
        height: isMobile ? 70 : 80 * Common.WIDTH_DIVISOR,
        flexDirection: "column",
    }, style
    ]}>
        <Text style={{
            fontSize: isMobile ? 14 : 16 * Common.WIDTH_DIVISOR,
            fontWeight: "500",
            marginLeft: isMobile ? 0 : 36 * Common.WIDTH_DIVISOR,
            color: "#000000",
        }}>{title}</Text>
        <TextInput style={{
            width: '100%',
            marginTop: isMobile ? 10 : 18 * Common.WIDTH_DIVISOR,
            height: 37 * Common.WIDTH_DIVISOR,
            borderRadius: 100 * Common.WIDTH_DIVISOR,
            backgroundColor: "#FFFFFF",
            borderStyle: "solid",
            borderWidth: 0.5,
            borderColor: "rgba(24, 134, 168, 1.0)",
            paddingHorizontal: isMobile ? 10 : 35 * Common.WIDTH_DIVISOR,
            placeholderTextColor: "#9C9C9C",
            fontSize: isMobile ? 12 : 12 * Common.WIDTH_DIVISOR,
        }}
            value={value}
            placeholder={placeholder}
            onChangeText={onChangeText}
            editable={editable}
            selectTextOnFocus={editable}
        />
    </View>
);
export const CustomImage = ({ title, src, onPress, style, onPressAdd = null, editWord = "" }) => (
    <TouchableOpacity onPress={onPress} style={{
        justifyContent: "center",
        alignItems: "center",
    }}>

        <View style={[{
            marginLeft: 10 * Common.WIDTH_DIVISOR,
            justifyContent: "center",
            alignItems: "center",
        }, style]}>{src ? <Image style={{
            width: 100 * Common.WIDTH_DIVISOR,
            height: 100 * Common.WIDTH_DIVISOR
        }} source={{ uri: src }} /> : <View style={{
            width: 100 * Common.WIDTH_DIVISOR,
            height: 100 * Common.WIDTH_DIVISOR
        }} />}

            <View style={{ flexDirection: "row" }}>
                <Text style={{
                    marginTop: 10 * Common.WIDTH_DIVISOR,
                    fontSize: 12 * Common.WIDTH_DIVISOR,
                    fontWeight: "400",
                    color: "#000000",
                }}>{title + " "}</Text>

                {onPressAdd ? <TouchableOpacity
                    onPress={onPressAdd}>
                    <Text style={{
                        marginTop: 10 * Common.WIDTH_DIVISOR,
                        fontSize: 12 * Common.WIDTH_DIVISOR,
                        fontWeight: "400",
                        color: "blue",
                    }}>{editWord ? editWord : "Update"}</Text>
                </TouchableOpacity> : null}
            </View>
        </View>
    </TouchableOpacity>
);

export const BanModal = ({ banModal, setBanModal, reason, setReason, onSubmit }) => {
    return (
        <Modal
            transparent={true}
            visible={banModal}
        >
            <View style={{
                flex: 1,
                alignItems: 'center',
                backgroundColor: 'rgba(0,0,0,0.5)',
            }}>
                <View style={{
                    marginTop: 100 * Common.WIDTH_DIVISOR,
                    width: 894 * Common.WIDTH_DIVISOR,
                    height: 500 * Common.WIDTH_DIVISOR,
                    borderRadius: 4,
                    backgroundColor: "#FFFFFF",
                    shadowColor: "rgba(0, 0, 0, 0.25)",
                    shadowOffset: {
                        width: 0,
                        height: 4
                    },
                    shadowRadius: 4,
                    shadowOpacity: 1

                }}>
                    <Image style={{
                        marginTop: 24 * Common.WIDTH_DIVISOR,
                        width: 82 * Common.WIDTH_DIVISOR,
                        height: 82 * Common.WIDTH_DIVISOR,
                        alignSelf: "center",
                    }} source={require("../../resource/Web/people-fill.png")} />

                    <Text style={{
                        marginTop: 8 * Common.WIDTH_DIVISOR,
                        fontSize: 18 * Common.WIDTH_DIVISOR,
                        fontWeight: "400",
                        textAlign: "center",
                        color: "#000000"
                    }}>Forbidden/Unblock</Text>

                    <View style={{
                        marginTop: 15 * Common.WIDTH_DIVISOR,
                        width: 803 * Common.WIDTH_DIVISOR,
                        height: 1,
                        backgroundColor: "rgba(24, 134, 168, 1.0)",
                        alignSelf: "center",
                    }}></View>

                    <View style={{
                        marginTop: 28 * Common.WIDTH_DIVISOR,
                        width: 368 * Common.WIDTH_DIVISOR,
                        alignSelf: "center",
                        flexDirection: "column",
                    }}>

                        <TextInput multiline={true}
                            rows={4}
                            style={{
                                width: 368 * Common.WIDTH_DIVISOR,
                                marginTop: 18 * Common.WIDTH_DIVISOR,
                                borderRadius: 4 * Common.WIDTH_DIVISOR,
                                backgroundColor: "#FFFFFF",
                                borderStyle: "solid",
                                borderWidth: 0.5,
                                borderColor: "rgba(24, 134, 168, 1.0)",
                                padding: 10 * Common.WIDTH_DIVISOR,
                                placeholderTextColor: "#9C9C9C",
                                fontSize: 12 * Common.WIDTH_DIVISOR,
                            }}
                            value={reason}
                            placeholder={"请输入理由"}
                            onChangeText={(text) => {
                                console.log(text)
                                setReason(text);
                            }}
                        />
                    </View>
                    <View style={{
                        flexDirection: "row",
                        marginTop: 40 * Common.WIDTH_DIVISOR,
                        alignSelf: "center"
                    }}>
                        <CustomButton
                            style={{
                                marginLeft: 20 * Common.WIDTH_DIVISOR
                            }}
                            title={"Close"}
                            onPress={() => {
                                setBanModal(false)
                            }} />
                        <CustomButton
                            style={{
                                marginLeft: 20 * Common.WIDTH_DIVISOR
                            }}
                            title={"Confirm"}
                            onPress={onSubmit} />
                    </View>
                </View>
            </View>
        </Modal>
    );
};

export const CustomPicker = ({ title, selectedValue, onValueChange, items, style }) => {
    return (
        <View style={[styles.pickerContainer, style]}>
            <Text style={styles.pickerTitle}>{title}</Text>
            <Picker
                selectedValue={selectedValue}
                onValueChange={onValueChange}
                style={styles.picker}
            >
                <Picker.Item label="Select an option" value="" />
                {items.map((item) => (
                    <Picker.Item key={item.value} label={item.label} value={item.value} />
                ))}
            </Picker>
        </View>
    );
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fff",
        alignItems: "center",
        justifyContent: "center",
    },
    loginImage: {
        width: 114,
        height: 114
    },
    loginText: {
        marginTop: 40,
        fontSize: 25,
        fontWeight: "400",
        color: "#000",
        textAlign: "left",
    },

    loginCell: {
        borderRadius: 8,
        borderStyle: "solid",
        borderColor: "#9c9c9c",
        borderWidth: 1,
        width: 627,
        height: 66,
        flexDirection: "row",
        alignItems: "center",
        marginTop: 15,
        paddingLeft: 20,
    },
    icon1: {
        width: 40,
        height: 40
    },
    textInput1: {
        marginLeft: 30,
        fontSize: 22,
    },
    selectedCategory: {
        justifyContent: "center",
        alignItems: "center",
        width: 255,
        height: 40,
        borderRadius: 8,
        backgroundColor: "#10869333",
        shadowColor: "rgba(0, 0, 0, 0.25)",
        shadowOffset: {
            width: 0,
            height: 4
        },
        shadowRadius: 4,
        shadowOpacity: 1
    },
    category: {
        justifyContent: "center",
        alignItems: "center",
        width: 255,
        height: 40,
        borderRadius: 8,
        backgroundColor: "#FFFFFF",
        shadowColor: "rgba(0, 0, 0, 0.25)",
        shadowOffset: {
            width: 0,
            height: 4
        },
        shadowRadius: 4,
        shadowOpacity: 1
    },
    categoryText: {
        width: 88,
        height: 27,
        fontFamily: "Inter",
        fontSize: 22,
        fontWeight: "400",
        fontStyle: "normal",
        lineHeight: 22,
        color: "#000000"
    },
    pageItem: {
        marginHorizontal: 5,
        fontSize: 18 * Common.WIDTH_DIVISOR,
        color: "#000000"
    },
    dateInput: {
        fontSize: isMobile ? 14 : 16 * Common.WIDTH_DIVISOR,
        width: isMobile ? '100%' : 150 * Common.WIDTH_DIVISOR,
        color: "#656464",
        borderRadius: 4 * Common.WIDTH_DIVISOR,
        backgroundColor: "#fff",
        borderStyle: "solid",
        borderColor: "rgba(156, 156, 156, 1.0)",
        borderWidth: 1,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 3,
        paddingBottom: 3,
        marginRight: isMobile ? 0 : 10,
        marginBottom: isMobile ? 10 : 0,
    },
    pickerContent: {
        marginLeft: 10 * Common.WIDTH_DIVISOR,
        fontSize: 16 * Common.WIDTH_DIVISOR,
        color: "#000000",
        borderStyle: "solid",
        width: 120 * Common.WIDTH_DIVISOR,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: "rgba(151, 151, 151, 1.0)",
    },
    pickerContainer: {
        marginBottom: 20,
        width: isMobile ? '100%' : 'auto',
    },
    pickerTitle: {
        fontSize: isMobile ? 14 : 16 * Common.WIDTH_DIVISOR,
        marginBottom: 5,
        color: '#333',
    },
    picker: {
        height: 50 * Common.WIDTH_DIVISOR,
        width: isMobile ? '100%' : 300 * Common.WIDTH_DIVISOR,
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 5,
    },
    paginationContainer: {
        marginTop: 20,
        alignItems: 'center',
    },
    pageInfo: {
        fontSize: isMobile ? 12 : 14,
        marginBottom: 10,
    },
    paginationControls: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    pageButton: {
        backgroundColor: '#f0f0f0',
        paddingVertical: isMobile ? 5 : 8,
        paddingHorizontal: isMobile ? 8 : 12,
        marginHorizontal: 5,
        borderRadius: 4,
    },
    pageButtonText: {
        fontSize: isMobile ? 12 : 14,
    },
    pageInputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 10,
    },
    pageInput: {
        width: isMobile ? 40 : 50,
        height: isMobile ? 30 : 36,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
        paddingHorizontal: 5,
        marginRight: 5,
        fontSize: isMobile ? 12 : 14,
    },
    pageText: {
        fontSize: isMobile ? 12 : 14,
    },
});