import React, { useEffect, useRef, useState } from 'react';
import { View, FlatList, StyleSheet, Text, TouchableOpacity, Modal, TextInput, ScrollView, Dimensions } from "react-native";
import Common from '../CommonStyles/common';
import { useGlobalContext } from "../GlobalContext/GlobalContext";
import { getOrderList, getOrderDetails, editOrder, getProductList, confirmOrder, shipOrder, readyForPickup, completeOrder, cancelOrder } from "../Util/api";
import { Pagination, CustomText, CustomButton, WebModal } from "./WebComponent"

const { width } = Dimensions.get('window');
const isMobile = width < 768;
const pageSize = 10;

export default function OrderManager() {
    const { jwt } = useGlobalContext();
    const [page, setPage] = useState(1);
    const [orders, setOrders] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeTab, setActiveTab] = useState("all");
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editedOrder, setEditedOrder] = useState(null);
    const [isProductModalVisible, setIsProductModalVisible] = useState(false);
    const [products, setProducts] = useState([]);
    const [productSearchTerm, setProductSearchTerm] = useState("");

    useEffect(() => {
        fetchOrders(page, searchTerm, activeTab);
    }, [page, searchTerm, activeTab]);

    const fetchOrders = async (currentPage, search, status) => {
        try {
            const response = await getOrderList({
                page: currentPage,
                limit: pageSize,
                orderNumber: search,
                status: status === "all" ? undefined : status
            });
            console.log('获取订单列表成功:', response);
            setOrders(response.orders);
            setTotalOrders(response.totalOrders);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('获取订单列表失败:', error);
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleSearch = (term) => {
        setSearchTerm(term);
        setPage(1);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setPage(1);
    };

    const handleViewDetails = async (orderId) => {
        try {
            const orderDetails = await getOrderDetails(orderId);
            console.log('获取订单详情成功:', orderDetails);
            setSelectedOrder(orderDetails.order);
            setEditedOrder(orderDetails.order);
            setIsModalVisible(true);
        } catch (error) {
            console.error('获取订单详情失败:', error);
        }
    };

    const handleEditOrder = async () => {
        try {
            console.log("editedOrder", editedOrder);
            await editOrder(editedOrder._id, {
                ...editedOrder,
                adminNote: editedOrder.adminNote
            });
            setIsModalVisible(false);
            fetchOrders(page, searchTerm, activeTab);
        } catch (error) {
            console.error('编辑订单失败:', error);
        }
    };

    const fetchProducts = async (search = "") => {
        try {
            const response = await getProductList(search);
            setProducts(response.products);
        } catch (error) {
            console.error('获取产品列表失败:', error);
        }
    };

    const calculateTotalAmount = (items) => {
        return items.reduce((total, item) => total + item.quantity * item.price, 0);
    };

    const handleAddProduct = (product) => {
        const existingItem = editedOrder.items.find(item => item.product._id === product._id);
        if (existingItem) {
            alert('商品已经存在，请去修改数量');
        } else {
            const newItem = {
                _id: Date.now().toString(),
                product: {
                    _id: product._id,
                    name: product.name,
                    originPrice: product.price
                },
                quantity: 1,
                price: product.price
            };
            const updatedItems = [...editedOrder.items, newItem];
            const newTotalAmount = calculateTotalAmount(updatedItems);
            setEditedOrder({ ...editedOrder, items: updatedItems, totalAmount: newTotalAmount });
            setIsProductModalVisible(false);
        }
    };

    const renderTabs = () => {
        const tabs = [
            { key: "all", title: "全部" },
            { key: "Pending", title: "新订单" },
            { key: "Processing", title: "已确认" },
            { key: "Shipping", title: "配送中" },
            { key: "WaitingPickUp", title: "待自取" },
            { key: "Completed", title: "已完成" },
            { key: "Cancelled", title: "已取消" },
        ];
        return (
            <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.tabContainer}>
                {tabs.map((tab) => (
                    <TouchableOpacity
                        key={tab.key}
                        style={[
                            styles.tab,
                            activeTab === tab.key && styles.activeTab
                        ]}
                        onPress={() => handleTabChange(tab.key)}
                    >
                        <Text style={[
                            styles.tabText,
                            activeTab === tab.key && styles.activeTabText
                        ]}>
                            {tab.title}
                        </Text>
                    </TouchableOpacity>
                ))}
            </ScrollView>
        );
    };

    const handleConfirmOrder = async (orderId) => {
        try {
            await confirmOrder(orderId);
            fetchOrders(page, searchTerm, activeTab);
        } catch (error) {
            console.error('确认订单失败:', error);
        }
    };

    const handleShipOrder = async (orderId) => {
        try {
            await shipOrder(orderId);
            fetchOrders(page, searchTerm, activeTab);
        } catch (error) {
            console.error('配送订单失败:', error);
        }
    };

    const handleReadyForPickup = async (orderId) => {
        try {
            await readyForPickup(orderId);
            fetchOrders(page, searchTerm, activeTab);
        } catch (error) {
            console.error('设置订单为可自取状态失败:', error);
        }
    };

    const handleCompleteOrder = async (orderId) => {
        try {
            await completeOrder(orderId);
            fetchOrders(page, searchTerm, activeTab);
        } catch (error) {
            console.error('完成订单失败:', error);
        }
    };

    const handleCancelOrder = async (orderId) => {
        try {
            await cancelOrder(orderId);
            fetchOrders(page, searchTerm, activeTab);
        } catch (error) {
            console.error('取消订单失败:', error);
        }
    };

    const renderOrderItem = ({ item }) => {
        if (isMobile) {
            return (
                <View style={styles.mobileOrderItem}>
                    <View style={styles.orderHeader}>
                        <CustomText title={item.orderNumber} style={styles.orderNumber} />
                        <CustomText title={new Date(item.createdAt).toLocaleDateString()} style={styles.orderDate} />
                    </View>
                    <View style={styles.orderDetails}>
                        <CustomText title={`用户: ${item.user.email}`} style={styles.orderDetail} />
                        <CustomText title={`总金额: $${item.totalAmount.toFixed(2)}`} style={styles.orderDetail} />
                        <CustomText title={`类型: ${item.deliveryType == "pickup" ? "自取" : "配送"}`} style={styles.orderDetail} />
                        <CustomText title={`状态: ${item.status}`} style={styles.orderDetail} />
                    </View>
                    <View style={styles.actionButtons}>
                        <CustomButton title="查看详情" onPress={() => handleViewDetails(item._id)} style={styles.actionButton} />
                        {item.status === "Pending" && (
                            <CustomButton title="确认" onPress={() => handleConfirmOrder(item._id)} style={styles.actionButton} />
                        )}
                        {item.status === "Processing" && item.deliveryType !== "pickup" && (
                            <CustomButton title="配送" onPress={() => handleShipOrder(item._id)} style={styles.actionButton} />
                        )}
                        {item.status === "Processing" && item.deliveryType === "pickup" && (
                            <CustomButton title="可自取" onPress={() => handleReadyForPickup(item._id)} style={styles.actionButton} />
                        )}
                        {(item.status === "Shipping" || item.status === "WaitingPickUp") && (
                            <CustomButton title="完成" onPress={() => handleCompleteOrder(item._id)} style={styles.actionButton} />
                        )}
                        {item.status !== "Completed" && item.status !== "Cancelled" && (
                            <CustomButton title="取消" onPress={() => handleCancelOrder(item._id)} style={styles.actionButton} />
                        )}
                    </View>
                </View>
            );
        } else {
            return (
                <View style={styles.desktopOrderItem}>
                    <CustomText title={item.orderNumber} width="15%" />
                    <CustomText title={item.user.email} width="15%" />
                    <CustomText title={`$${item.totalAmount.toFixed(2)}`} width="10%" />
                    <CustomText title={item.deliveryType == "pickup" ? "自取" : "配送"} width="10%" />
                    <CustomText title={item.status} width="10%" />
                    <CustomText title={new Date(item.createdAt).toLocaleDateString()} width="15%" />
                    <View style={styles.actionButtons}>
                        <CustomButton title="查看详情" onPress={() => handleViewDetails(item._id)} />
                        {item.status === "Pending" && (
                            <CustomButton title="确认" onPress={() => handleConfirmOrder(item._id)} />
                        )}
                        {item.status === "Processing" && item.deliveryType !== "pickup" && (
                            <CustomButton title="配送" onPress={() => handleShipOrder(item._id)} />
                        )}
                        {item.status === "Processing" && item.deliveryType === "pickup" && (
                            <CustomButton title="可自取" onPress={() => handleReadyForPickup(item._id)} />
                        )}
                        {(item.status === "Shipping" || item.status === "WaitingPickUp") && (
                            <CustomButton title="完成" onPress={() => handleCompleteOrder(item._id)} />
                        )}
                        {item.status !== "Completed" && item.status !== "Cancelled" && (
                            <CustomButton title="取消" onPress={() => handleCancelOrder(item._id)} />
                        )}
                    </View>
                </View>
            );
        }
    };

    const debounceTimeoutRef = useRef(null);

    const handleSearchChange = (text) => {
        console.log(text);
        setProductSearchTerm(text);

        // 清除之前的延迟调用
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        // 设置新的延迟调用
        debounceTimeoutRef.current = setTimeout(() => {
            fetchProducts(text);
        }, 500); // 500 毫秒的延迟
    };


    const renderProductList = () => (
        <WebModal visible={isProductModalVisible} onClose={() => setIsProductModalVisible(false)}>
            <View style={styles.modalContent}>
                <Text style={styles.modalTitle}>选择产品</Text>
                <TextInput
                    style={styles.searchInput}
                    placeholder="搜索产品"
                    value={productSearchTerm}
                    onChangeText={handleSearchChange}
                />
                <FlatList
                    data={products}
                    keyExtractor={(item) => item._id}
                    renderItem={({ item }) => (
                        <TouchableOpacity
                            style={styles.productItem}
                            onPress={() => handleAddProduct(item)}
                        >
                            <Text>{item.name}</Text>
                            <Text>${item.price.toFixed(2)}</Text>
                        </TouchableOpacity>
                    )}
                />
            </View>
        </WebModal>
    );

    const renderOrderDetails = () => (
        <WebModal visible={isModalVisible} onClose={() => setIsModalVisible(false)}>
            <View style={styles.modalContent}>
                <Text style={styles.modalTitle}>订单详情</Text>
                <ScrollView>
                    <View style={styles.detailItem}>
                        <Text style={styles.detailLabel}>订单号：</Text>
                        <Text style={styles.detailValue}>{editedOrder?.orderNumber}</Text>
                    </View>
                    <View style={styles.detailItem}>
                        <Text style={styles.detailLabel}>下单时间</Text>
                        <Text style={styles.detailValue}>{new Date(editedOrder?.createdAt).toLocaleString()}</Text>
                    </View>
                    <View style={styles.detailItem}>
                        <Text style={styles.detailLabel}>用户名：</Text>
                        <Text style={styles.detailValue}>{editedOrder?.user.name}</Text>
                    </View>
                    <View style={styles.detailItem}>
                        <Text style={styles.detailLabel}>用户邮箱：</Text>
                        <Text style={styles.detailValue}>{editedOrder?.user.email}</Text>
                    </View>
                    <View style={styles.detailItem}>
                        <Text style={styles.detailLabel}>用户备注：</Text>
                        <Text style={styles.detailValue}>{editedOrder?.userNote}</Text>
                    </View>
                    <View style={styles.detailItem}>
                        <Text style={styles.detailLabel}>配送方式：</Text>
                        <Text style={styles.detailValue}>{editedOrder?.deliveryType == "pickup" ? "自取" : "配送"}</Text>
                    </View>
                    <View style={styles.detailItem}>
                        <Text style={styles.detailLabel}>地址</Text>
                        <Text style={styles.detailValue}>{editedOrder?.address}</Text>
                    </View>
                    <View style={styles.detailItem}>
                        <Text style={styles.detailLabel}>状态：</Text>
                        <Text style={styles.detailValue}>{editedOrder?.status}</Text>
                    </View>
                    {(editedOrder?.status === "Pending" || editedOrder?.status === "Processing") && (
                        <>
                            <View style={{
                                flexDirection: "row",
                                alignItems: "center"
                            }}>
                                <Text style={styles.sectionTitle}>商品列表</Text>
                                <CustomButton title="添加商品" onPress={() => {
                                    setIsProductModalVisible(true);
                                    fetchProducts();
                                }} style={{ marginLeft: 100 }} />
                            </View>
                            {editedOrder?.items.map((item, index) => (
                                <View key={item._id} style={styles.itemContainer}>
                                    <View style={styles.itemDetails}>
                                        <Text style={{ ...styles.itemName, width: 300 }}>{item.product.name}</Text>
                                        <Text style={styles.itemName}>数量：</Text>
                                        <TextInput
                                            style={styles.itemInput}
                                            value={item.quantity.toString()}
                                            onChangeText={(text) => updateItemQuantity(index, parseInt(text))}
                                            keyboardType="numeric"
                                        />
                                        <Text style={{ ...styles.itemName, marginLeft: 20 }}>价格:$</Text>
                                        <TextInput
                                            style={styles.itemInput}
                                            value={item.price.toFixed(2)}
                                            onChangeText={(text) => updateItemPrice(index, parseFloat(text))}
                                            keyboardType="numeric"
                                        />
                                        <TouchableOpacity onPress={() => removeItem(index)}>
                                            <Text style={{ ...styles.removeButton, marginLeft: 20 }}>删除</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            ))}
                            <View style={styles.detailItem}>
                                <Text style={styles.detailLabel}>管理员备注：</Text>
                                <TextInput
                                    style={styles.detailInput}
                                    value={editedOrder?.adminNote || ''}
                                    onChangeText={(text) => setEditedOrder({ ...editedOrder, adminNote: text })}
                                    multiline={true}
                                    numberOfLines={3}
                                />
                            </View>
                        </>
                    )}
                    {editedOrder?.status !== "Pending" && editedOrder?.status !== "Processing" && (
                        <>
                            <Text style={styles.sectionTitle}>商品列表</Text>
                            {editedOrder?.items.map((item) => (
                                <View key={item._id} style={styles.itemContainer}>
                                    <Text style={styles.itemName}>{item.product.name}</Text>
                                    <Text>数量：{item.quantity}</Text>
                                    <Text>价格：${item.price.toFixed(2)}</Text>
                                </View>
                            ))}
                            <View style={styles.detailItem}>
                                <Text style={styles.detailLabel}>管理员备注：</Text>
                                <Text style={styles.detailValue}>{editedOrder?.adminNote || '无'}</Text>
                            </View>
                        </>
                    )}

                    <View style={{ ...styles.detailItem, marginTop: 20 }}>
                        <Text style={styles.detailLabel}>总金额：</Text>
                        <Text style={styles.detailValue}>${editedOrder?.totalAmount.toFixed(2)}</Text>
                    </View>
                </ScrollView>
                <View style={styles.modalButtons}>
                    <CustomButton title="关闭" onPress={() => setIsModalVisible(false)} />
                    {(editedOrder?.status === "Pending" || editedOrder?.status === "Processing") && (
                        <CustomButton title="保存" onPress={handleEditOrder} />
                    )}
                </View>
            </View>
        </WebModal>
    );

    const updateItemQuantity = (index, quantity) => {
        const updatedItems = [...editedOrder.items];
        updatedItems[index].quantity = quantity;
        const newTotalAmount = calculateTotalAmount(updatedItems);
        setEditedOrder({ ...editedOrder, items: updatedItems, totalAmount: newTotalAmount });
    };

    const updateItemPrice = (index, price) => {
        const updatedItems = [...editedOrder.items];
        updatedItems[index].price = price;
        const newTotalAmount = calculateTotalAmount(updatedItems);
        setEditedOrder({ ...editedOrder, items: updatedItems, totalAmount: newTotalAmount });
    };

    const removeItem = (index) => {
        const updatedItems = editedOrder.items.filter((_, i) => i !== index);
        const newTotalAmount = calculateTotalAmount(updatedItems);
        setEditedOrder({ ...editedOrder, items: updatedItems, totalAmount: newTotalAmount });
    };

    return (
        <View style={styles.container}>
            <View style={styles.tabWrapper}>
                {renderTabs()}
            </View>
            {!isMobile && (
                <View style={styles.tableHeader}>
                    <CustomText title="订单号" width="15%" />
                    <CustomText title="用户邮箱" width="15%" />
                    <CustomText title="总金额" width="10%" />
                    <CustomText title="类型" width="10%" />
                    <CustomText title="状态" width="10%" />
                    <CustomText title="创建时间" width="15%" />
                    <CustomText title="操作" width="25%" />
                </View>
            )}
            <FlatList
                data={orders}
                renderItem={renderOrderItem}
                keyExtractor={(item) => item.orderNumber}
            />
            <Pagination
                currentPage={page}
                totalItems={totalOrders}
                totalPages={totalPages}
                itemsPerPage={pageSize}
                onPageChange={handlePageChange}
            />
            {renderOrderDetails()}
            {renderProductList()}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fff",
        padding: isMobile ? 10 : 20 * Common.WIDTH_DIVISOR,
        paddingTop: isMobile ? 50 : 20 * Common.WIDTH_DIVISOR, // 为移动端添加顶部边距
    },
    tableHeader: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#ddd",
        paddingBottom: 10 * Common.WIDTH_DIVISOR,
        marginBottom: 10 * Common.WIDTH_DIVISOR,
    },
    desktopOrderItem: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#eee",
        paddingVertical: 10 * Common.WIDTH_DIVISOR,
    },
    mobileOrderItem: {
        flexDirection: "column",
        borderBottomWidth: 1,
        borderBottomColor: "#eee",
        paddingVertical: 10 * Common.WIDTH_DIVISOR,
        backgroundColor: '#fff',
        borderRadius: 8,
        marginBottom: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        elevation: 4,
    },
    orderHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    orderNumber: {
        fontWeight: 'bold',
        fontSize: isMobile ? 14 : 16 * Common.WIDTH_DIVISOR,
    },
    orderDate: {
        fontSize: isMobile ? 12 : 14 * Common.WIDTH_DIVISOR,
        color: '#666',
    },
    orderDetails: {
        marginBottom: 10,
    },
    orderDetail: {
        fontSize: isMobile ? 12 : 14 * Common.WIDTH_DIVISOR,
        marginBottom: 3,
    },
    actionButtons: {
        flexDirection: "row",
        flexWrap: 'wrap',
        justifyContent: "flex-start",
    },
    actionButton: {
        marginRight: 10,
        marginBottom: 10,
        width:'auto',
    },
    tabWrapper: {
        marginBottom: isMobile ? 10 : 20 * Common.WIDTH_DIVISOR,
    },
    tabContainer: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
    },
    tab: {
        paddingVertical: isMobile ? 5 : 10 * Common.WIDTH_DIVISOR,
        paddingHorizontal: isMobile ? 10 : 20 * Common.WIDTH_DIVISOR,
        marginRight: isMobile ? 5 : 10 * Common.WIDTH_DIVISOR,
    },
    activeTab: {
        borderBottomWidth: 2,
        borderBottomColor: '#007bff',
    },
    tabText: {
        fontSize: isMobile ? 12 : 16 * Common.WIDTH_DIVISOR,
        color: '#333',
    },
    activeTabText: {
        fontWeight: 'bold',
        color: '#007bff',
    },
    modalContent: {
        marginTop: isMobile ? 20 : 50 * Common.WIDTH_DIVISOR,
        backgroundColor: 'white',
        padding: isMobile ? 15 : 20 * Common.WIDTH_DIVISOR,
        borderRadius: 10 * Common.WIDTH_DIVISOR,
        width: isMobile ? '95%' : '80%',
    },
    modalTitle: {
        fontSize: isMobile ? 18 : 20 * Common.WIDTH_DIVISOR,
        fontWeight: 'bold',
        marginBottom: isMobile ? 15 : 20 * Common.WIDTH_DIVISOR,
    },
    detailItem: {
        flexDirection: isMobile ? 'column' : 'row',
        marginBottom: 10 * Common.WIDTH_DIVISOR,
    },
    detailLabel: {
        width: isMobile ? '100%' : '30%',
        fontWeight: 'bold',
        marginBottom: isMobile ? 5 : 0,
    },
    detailValue: {
        width: isMobile ? '100%' : '70%',
    },
    detailInput: {
        width: isMobile ? '100%' : '70%',
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 5 * Common.WIDTH_DIVISOR,
        padding: 5 * Common.WIDTH_DIVISOR,
        textAlignVertical: 'top',
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 20 * Common.WIDTH_DIVISOR,
    },
    sectionTitle: {
        fontSize: isMobile ? 16 : 18 * Common.WIDTH_DIVISOR,
        fontWeight: 'bold',
        marginTop: 20 * Common.WIDTH_DIVISOR,
        marginBottom: 10 * Common.WIDTH_DIVISOR,
    },
    itemContainer: {
        marginBottom: 10 * Common.WIDTH_DIVISOR,
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
        paddingBottom: 10 * Common.WIDTH_DIVISOR,
    },
    itemName: {
        fontSize: isMobile ? 12 : 14 * Common.WIDTH_DIVISOR,
        fontWeight: 'bold',
    },
    itemDetails: {
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'flex-start' : 'center',
        marginTop: 5 * Common.WIDTH_DIVISOR,
    },
    itemInput: {
        width: isMobile ? '100%' : 50,
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 5 * Common.WIDTH_DIVISOR,
        padding: 5 * Common.WIDTH_DIVISOR,
        marginBottom: isMobile ? 5 : 0,
    },
    removeButton: {
        color: 'red',
        fontSize: isMobile ? 12 : 14 * Common.WIDTH_DIVISOR,
    },
    addButton: {
        backgroundColor: '#007bff',
        marginLeft: isMobile ? 0 : 200,
        padding: 10 * Common.WIDTH_DIVISOR,
        borderRadius: 5 * Common.WIDTH_DIVISOR,
        alignItems: 'center',
        marginTop: 10 * Common.WIDTH_DIVISOR,
    },
    addButtonText: {
        color: 'white',
        fontSize: isMobile ? 10 : 12 * Common.WIDTH_DIVISOR,
    },
    searchInput: {
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 5 * Common.WIDTH_DIVISOR,
        padding: 10 * Common.WIDTH_DIVISOR,
        marginBottom: 10 * Common.WIDTH_DIVISOR,
    },
    productItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10 * Common.WIDTH_DIVISOR,
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
    },
});